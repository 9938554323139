import Vue from 'vue'
import 'vant/lib/button/style'
import {
  Col,
  Row,
  Button,
  Toast,
  Cell,
  CellGroup,
  Popup,
  Icon,
  Image as VanImage,
  Lazyload,
  Loading,
  Form,
  Field,
  Picker,
  Area,
  Tab,
  Tabs,
  ActionSheet,
  CheckboxGroup,
  Checkbox,
  Overlay,
  CountDown,
  RadioGroup,
  Radio,
  Grid,
  GridItem,
  Swipe,
  SwipeItem,
  List,
} from 'vant'

Vue.use(Col)
Vue.use(List)
Vue.use(Row)
Vue.use(Button)
Vue.use(Toast)
Vue.use(Cell)
Vue.use(CellGroup)
Vue.use(Popup)
Vue.use(Icon)
Vue.use(Radio)
Vue.use(RadioGroup)
Vue.use(VanImage)
Vue.use(Lazyload, {
  lazyComponent: true,
})
Vue.use(Loading)
Vue.use(Form)
Vue.use(Field)
Vue.use(Picker)
Vue.use(Area)
Vue.use(Tab)
Vue.use(Tabs)
Vue.use(ActionSheet)
Vue.use(CheckboxGroup)
Vue.use(Checkbox)
Vue.use(Overlay)
Vue.use(CountDown)
Vue.use(Grid)
Vue.use(GridItem)
Vue.use(Swipe)
Vue.use(SwipeItem)
