import Vue from 'vue'
import App from './App.vue'
import router from './router'
import 'amfe-flexible'
import VueWechatTitle from 'vue-wechat-title'
import antIShake from '@/utils/antishake'

Vue.directive('antIShake', antIShake)
// import './utils/vconsole'

import './plugins/vant'
import './styles/index.scss'
import { Plugin } from 'vue-fragment'

Vue.use(VueWechatTitle)
Vue.use(Plugin)

console.log('版本时间戳：', '15:12')
new Vue({
  render: h => h(App),
  router,
}).$mount('#app')
