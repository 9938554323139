export default {
  inserted(el, binding, vnode) {
    let timer = {}
    el.addEventListener('click', () => {
      if (timer) {
        clearTimeout(timer)
      }
      timer = setTimeout(() => {
        binding.value()
      }, 600)
    })
  },
}
